import {NgModule} from '@angular/core';
import {LegalPageComponent} from './legal-page.component';
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import {MenuComponent} from '../../components/menu/menu.component';
import {ComponentsModule} from '../../components/components.module';


@NgModule({
	declarations: [
		LegalPageComponent,
	],
	imports: [
		ScrollToModule.forRoot(),
		ComponentsModule
	],
	providers: [],
	exports: []
})
export class LegalPageModule {
}
