import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ComponentsModule} from './components/components.module';
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import {HomePageComponent} from './pages/home-page/home-page.component';
import {LegalPageComponent} from './pages/legal-page/legal-page.component';
import {HomePageModule} from './pages/home-page/home-page.module';
import {LegalPageModule} from './pages/legal-page/legal-page.module';

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		BrowserModule.withServerTransition({appId: 'serverApp'}),
		AppRoutingModule,
		ComponentsModule,
		ScrollToModule.forRoot(),
		/* PAGES */
		HomePageModule,
		LegalPageModule
	],
	providers: [],
	bootstrap: [AppComponent],
	exports: []
})
export class AppModule {
}
