import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LegalPageComponent} from './pages/legal-page/legal-page.component';
import {HomePageComponent} from './pages/home-page/home-page.component';

const routes: Routes = [
	{
		path: '',
		component: HomePageComponent
	}, {
		path: 'legal',
		component: LegalPageComponent
	}, {
		path: '**',
		redirectTo: ''
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
