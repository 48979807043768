import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'app-home-page',
	templateUrl: './legal-page.component.html',
	styleUrls: ['./legal-page.component.scss']
})
export class LegalPageComponent implements OnInit {
	
	constructor() {
	}
	
	ngOnInit() {
		console.log('hello-> ');
	}
	
}
