import {MenuComponent} from './menu/menu.component';
import {NgModule} from '@angular/core';
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';

@NgModule({
	declarations: [
		MenuComponent,
	
	],
	imports: [
	],
	providers: [],
	exports: [
		MenuComponent,
	]
})
export class ComponentsModule {
}
