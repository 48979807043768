import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
	
	@Output() scrollToId = new EventEmitter<any>();
	
	constructor(public elRef: ElementRef) {
	}
	
	ngOnInit() {
	}
	
}
