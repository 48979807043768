import {Component, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {MenuComponent} from './components/menu/menu.component';
import {Router} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';
import {ScrollToService} from '@nicky-lenaers/ngx-scroll-to';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	title = 'Betaginn - Dentista en Bilbao';
	menuVisible = false;
	bodyScrollPosition = 0;
	htmlScrollPosition = 0;
	
	@ViewChild('menu', {static:false}) menu: MenuComponent;
	@ViewChild('body', {static:false}) body: Body;
	
	constructor(@Inject(PLATFORM_ID) private platformId: Object, private router: Router, private scrollTo: ScrollToService) {
		
		if (isPlatformBrowser(this.platformId)) {
			
			console.log('this.platformId-> ', this.platformId);
			
			router.events.subscribe((val) => {
				
				if (this.menuVisible) {
					this.toggleMenu(false);
				} else {
					
					const body = document.body; // Safari
					const html = document.documentElement;
					body.scrollTop = 0;
					html.scrollTop = 0;
				}
				
			});
		}
		
	}
	
	toggleMenu(scroll: boolean = true) {
		
		if (isPlatformBrowser(this.platformId)) {
			
			this.menuVisible = !this.menuVisible;
			
			const body = document.body; // Safari
			const html = document.documentElement;
			
			if (!this.menuVisible && scroll) {
				/* Chrome, Firefox, IE and Opera places the overflow at the <html> level, unless else is specified.
                 Therefore, we use the documentElement property for these browsers */
				
				setTimeout(() => {
					body.scrollTop = this.bodyScrollPosition;
					html.scrollTop = this.htmlScrollPosition;
				}, 1);
				
				
			} else {
				// this.bodyScrollPosition = body.scrollTop;
				this.htmlScrollPosition = html.scrollTop;
				
				body.scrollTop = 0;
				html.scrollTop = 0;
			}
		}
	}
	
	ngOnInit() {
	}
	
	scrollToId($event: any) {
		
		if (this.router.url !== '/') {
			this.router.navigate(['/']);
		}
		
		this.toggleMenu();
		
		console.log('$event-> ', $event);
		setTimeout(() => {
			this.scrollTo.scrollTo(
				{
					target: $event,
					offset: -100
				}
			);
		}, 200);
		
		
	}
}
