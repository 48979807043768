import {NgModule} from '@angular/core';
import {HomePageComponent} from './home-page.component';
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import {MenuComponent} from '../../components/menu/menu.component';
import {ComponentsModule} from '../../components/components.module';
import {RouterModule} from '@angular/router';



@NgModule({
	declarations: [
		HomePageComponent,
	],
    imports: [
        ScrollToModule.forRoot(),
        ComponentsModule,
        RouterModule
    ],
	providers: [],
	exports: []
})
export class HomePageModule {
}
